@import url(https://fonts.googleapis.com/css?family=Crimson+Text&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Header-container {
  display: flex;
  flex-direction: column;
  font-family: 'Crimson Text', serif
}

.Header-name {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  /* background-color: green; */
}

.Topnav-row {
  display: flex;
  /* background-color: gray; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 10px 0px;
}

.Topnav-button {
  border: none;
  background-color: transparent;
  text-align: center;
  /* background-color: cyan; */
  margin: 0px 20px 0px 20px;
  font-family: 'Crimson Text', serif;
  font-size: 16px;
  outline-width: 0px;
  cursor: pointer;
}
.Copyright {
  display: flex;
  justify-content: center;
  font-family: 'Crimson Text', serif;
  font-size: 12px;
}
.Scrim-container {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.50;
}
.Home-container {
  display: flex;
  /* background-color: red; */
  width: 100%;
  justify-content: center;
}
.Outer-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.Paintings-container {
  display: flex;
  width: 70%; 
}

.Paintings-row {
  display: flex;
  flex-direction: row;
  /* background-color: blue; */
  flex-wrap: wrap;
  justify-content: center;
}

.Painting-entry {
  /* display: flex; */
  /* background-color: gray; */
  margin: 5px;
}
.Frame-container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -274px;
  margin-top: -208px;
  background-color: white;
  width: 549px;
  height: 436px;
}

.Image-container {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  width: 529px;
  height: 396px;
  align-self: center;
  /* background-color: green; */
}

.Image-details {
  display: flex;
  position: relative;
  max-width: 80%;
  margin-top: 5px;
  margin-left: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: 'Crimson Text';
  font-size: 14px;
  /* font-weight: bold; */
  /* color: white; */
}

.Frame-container-square {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -273px;
  margin-top: -260px;
  background-color: white;
  width: 547px;
  height: 520px;
}

.Image-container-square {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  width: 527px;
  height: 480px;
  align-self: center;
  /* background-color: green; */
}

.Image-details-square {
  display: flex;
  position: relative;
  max-width: 80%;
  margin-top: 5px;
  margin-left: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: 'Crimson Text';
  font-size: 14px;
  /* font-weight: bold; */
  /* color: white; */
}
.Contact-container {

}

.Contact-text {
  font-family: 'Crimson Text', serif;
  font-size: 16px;
}
.Main-container {
  width: 100%;
  min-height: 100vh;
  /* background-color: blue; */
  background-color: snow;
}

.Paintings-section {
  display: flex;
  width: 100%;
  justify-content: center;
  /* background-attachment: magenta; */
}

.Footer-section {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  /* background-color: blue; */
}
.App-container {
  /* background-color: green; */
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

