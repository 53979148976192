.Frame-container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -274px;
  margin-top: -208px;
  background-color: white;
  width: 549px;
  height: 436px;
}

.Image-container {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  width: 529px;
  height: 396px;
  align-self: center;
  /* background-color: green; */
}

.Image-details {
  display: flex;
  position: relative;
  max-width: 80%;
  margin-top: 5px;
  margin-left: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: 'Crimson Text';
  font-size: 14px;
  /* font-weight: bold; */
  /* color: white; */
}

.Frame-container-square {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -273px;
  margin-top: -260px;
  background-color: white;
  width: 547px;
  height: 520px;
}

.Image-container-square {
  display: flex;
  margin-left: 10px;
  margin-top: 10px;
  width: 527px;
  height: 480px;
  align-self: center;
  /* background-color: green; */
}

.Image-details-square {
  display: flex;
  position: relative;
  max-width: 80%;
  margin-top: 5px;
  margin-left: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: 'Crimson Text';
  font-size: 14px;
  /* font-weight: bold; */
  /* color: white; */
}