.Main-container {
  width: 100%;
  min-height: 100vh;
  /* background-color: blue; */
  background-color: snow;
}

.Paintings-section {
  display: flex;
  width: 100%;
  justify-content: center;
  /* background-attachment: magenta; */
}

.Footer-section {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  /* background-color: blue; */
}