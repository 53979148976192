.Outer-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.Paintings-container {
  display: flex;
  width: 70%; 
}

.Paintings-row {
  display: flex;
  flex-direction: row;
  /* background-color: blue; */
  flex-wrap: wrap;
  justify-content: center;
}

.Painting-entry {
  /* display: flex; */
  /* background-color: gray; */
  margin: 5px;
}