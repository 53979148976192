.Header-container {
  display: flex;
  flex-direction: column;
  font-family: 'Crimson Text', serif
}

.Header-name {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  /* background-color: green; */
}

.Topnav-row {
  display: flex;
  /* background-color: gray; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 10px 0px;
}

.Topnav-button {
  border: none;
  background-color: transparent;
  text-align: center;
  /* background-color: cyan; */
  margin: 0px 20px 0px 20px;
  font-family: 'Crimson Text', serif;
  font-size: 16px;
  outline-width: 0px;
  cursor: pointer;
}